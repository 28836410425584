// EnvironmentDay2023
import React from 'react';
import EnvironmentDay2023 from 'views/EnvironmentDay2023';

const AccountGeneralPage = () => {
  return <EnvironmentDay2023/>;
};

export default AccountGeneralPage;

// EnvironmentDay2023